@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Saira+Condensed&family=Nunito:wght@200;400;600;800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

p {
  max-width: 500px;
}
